var angular = require('angular');
var angularMocks = require('angular-mocks');

var css = require('./whois.css');

var app = angular.module("whoisApp", []);

app.controller("whoisCtrl", ['$scope', '$http', function($scope, $http) {

  var QueryObjects = {
    DOMAIN: "Domain",
    REGISTRAR: "Registrar",
    HOST: "Host"
  };

  $scope.queryObjects = [QueryObjects.DOMAIN, QueryObjects.REGISTRAR, QueryObjects.HOST];
  $scope.queryObject = QueryObjects.DOMAIN; // default to Domain as query object
  $scope.searchResult = null;
  $scope.searchQuery = null;
  $scope.search = search;

  $http.get('/whois/icann/uiconfig').then(function successCallback(response) {
    $scope.tld = "." + response.data.data.topLevelDomain;
  });

  function createPostObject() {
    var _name = $scope.searchQuery;
    var _category = $scope.queryObject;

    if (_category === QueryObjects.DOMAIN) {
      _name += $scope.tld;
    }

    return {
      category: _category.toUpperCase(),
      name: _name
    }
  }

  function search() {
    $http.post('/whois/icann/search', createPostObject()).then(
      function successCallback(response) {
        $scope.searchResult = response.data.data.details;
      }
    );
  }
}]);